.card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  /* outline: 1px solid #A6AAAE; */
  overflow-x: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.querycard {
  background-color: #f2f2f2;
  border-radius: 6px;
  outline: 1px solid #A6AAAE;
  overflow-x: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

div {
  white-space: pre-wrap;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

h4,
h5 {
  margin-left: 5px;
}
