.badge {
  background-color: #8223D2;
  color: white;
  border-radius: 12px;
  padding: 5px 1rem;
  margin-right: 1rem;
}

.header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #500474; */
  padding: 0 0.5%;
}

.header a {
  text-decoration: none;
  font-size: 1.25rem;
  color: #999899;
}

.header a:hover,
.header a:active,
.header a.active {
  color: white;
}

.header li {
  margin-left: 2rem;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.logo {
  font-size: 1.5rem;
  /* color: white; */
  font-weight: bold;
}
